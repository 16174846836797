<template>
  <el-container style="height: 100%">
    <el-header
      class="flexbox"
      style="
        height: 50px;
        justify-content: space-between;
        box-shadow: 0 1px 8px 1px #d6d5d5;
        z-index: 999;
      "
    >
      <div class="header-right flexbox">
        <img style="height: 40px" src="@/assets/banquet-img/logo.png" alt="" />
        <div class="menu-list flexbox" style="margin-left: 50px">
          <div
            @click="$router.push(menu.router)"
            v-for="(menu, index) in menulist[menuindex].children"
            :key="index"
          >
            <div
              :class="menu.router === $route.path ? 'select' : ''"
              class="menu-box"
              v-if="menu.show"
            >
              <img
                v-if="menu.router === $route.path && menu.img_select"
                :src="menu.img_select"
                alt=""
              />
              <img v-else :src="menu.img" alt="" />
              {{ menu.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="loginout flexbox">
        <el-badge
          :value="unnoticeValue"
          style="cursor: pointer;margin-right: 24px; margin-bottom: -10px"
		  @click="showNotice"
        >
          <el-icon size="20"><Bell /></el-icon>
        </el-badge>
        <div v-if="systemVersion !== ''">
          <span class="version">{{ systemVersion }}</span>
        </div>
        <div class="facesbox">
          <div
            class="touxiang"
            style="min-width: 100px; height: 38px; text-align: center"
          >
            <p style="margin-top: 0px">{{ restaurantName }}</p>
            <p>{{ userName }}</p>
          </div>
        </div>
        <!-- <img style="width: 22px; height: 24px;cursor: pointer;" src="@/assets/banquet-img/caidan.png" alt="" @click="systemLogout"/> -->
        <el-dropdown trigger="click">
          <img
            style="width: 38px; height: 38px; cursor: pointer"
            src="@/assets/banquet-img/user.png"
            alt=""
          />
          <template #dropdown>
            <el-dropdown-menu
              style="
                width: 80px;
                text-align: center;
                cursor: pointer;
                color: #666666;
                font-size: 14px;
              "
            >
              <el-dropdown-item>
                <el-switch
                  v-model="noticeFlag"
                  inline-prompt
                  style="--el-switch-on-color: #13ce66"
                  active-text="消息"
                  inactive-text="消息"
                  @change="noticeChange"
              /></el-dropdown-item>
              <el-dropdown-item @click="changeShow = true"
                >修改密码</el-dropdown-item
              >
              <el-dropdown-item @click="showList = true"
                >切换餐厅</el-dropdown-item
              >
              <el-dropdown-item @click="systemLogout"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </el-header>
    <el-container style="height: 100%; overflow: hidden">
      <el-aside
        class="flexbox"
        style="
          width: 70px;
          padding: 28px 0;
          flex-direction: column;
          box-shadow: 1px 8px 8px 1px #d6d5d5;
          z-index: 100;
        "
      >
        <div class="menu-left flexbox" style="flex: 1; flex-direction: column">
          <div
            @click="changeMenu(index, menu)"
            v-for="(menu, index) in menulist"
            :key="index"
            class="menu-leftbox"
          >
            <div v-if="menu.show">
              <img :src="menu.img" alt="" />
              {{ menu.name }}
            </div>
          </div>
        </div>
      </el-aside>
      <el-main style="background: #f3f3f3; overflow: hidden">
        <router-view></router-view>
      </el-main>
    </el-container>
    <el-dialog style="width: 400px" v-model="changeShow" title="修改密码">
      <el-form label-width="100px">
        <el-form-item label="登录账户">
          <el-input v-model="userNumber" disabled></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input
            v-model="password"
            placeholder="请输入新密码"
            type="password"
          ></el-input>
        </el-form-item>
        <div style="text-align: center">
          <el-button @click="cancel">取消</el-button>
          <el-button @click="change" type="primary">确定</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog v-model="showList" title="切换餐厅">
      <div class="reslistbox">
        <div
          @click="changeRes(r)"
          v-for="(r, index) in resList"
          :key="index"
          class="resbox"
        >
          <span>{{ r.name }}</span>
          <span>{{ r.time }}</span>
        </div>
      </div>
    </el-dialog>
    <el-drawer v-model="noticeDrawer" size="470" title="消息通知" direction="rtl">
      <div class="notice-drawer">
        <div class="head">
          <div  class="head-item">
            <span>未阅：<span style="color: red">{{unnoticeValue}}</span></span>
            <span style="margin-left: 20px">已阅：{{noticeValue}}</span>
          </div>
          <div> <el-button type="danger" @click="allRead">全部阅读</el-button></div>
        </div>
		<div v-for="(item,i) in noticeList" :key="i"
		class="notice-item"
		:class="{'gray':item.status==='1'}"
		 @click="noticeClick(item)">
			<div>
				<div>
					<span class="notice-name" 
					:class="{'red':item.eventSource==='撤单','blue':item.eventSource==='换桌'}">{{item.eventSource}}</span>
								<span style="margin:0 10px;">{{timeago(item.createTime)}}</span>
				<span style="font-weight: 600;">{{item.eatDate.substring(5,item.eatDate.length)}}&nbsp;&nbsp;{{item.segmentName}}</span>
					<span style="margin-left:10px">{{item.customerInfo}}&nbsp;<span v-if="item.phone">{{item.phone}}</span></span>
				</div>
					<div style="margin-top:4px">
						<span>{{(item.receiverOrderName||'')+'('+item.fromDevice+')'}}</span>
					<span style="margin-left:20px;">{{item.tableName}},就餐人数:{{item.reservedPeoples}}</span>
					</div>
			</div>
				  <el-radio v-model="item.status" :disabled="item.status==='1'" value="1"></el-radio>
	
		</div>
      </div>
    </el-drawer>
  </el-container>
</template>

<script setup>
import { onMounted, ref, nextTick } from "vue";
import bus from "@/utils/bus";
import { changePassword, getRestaurantList_api } from "@/api/system/user";
import { Bell } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import {
  getRestaurant,
  getUserinfo,
  getCloseNotice,
  switchCloseNotice,
  toNoticeHas,
  getNoticeList,
  editNoticeStatus
} from "@/api/home/home";
import { setSystemControlInfo } from "@/utils/ruoyi/ruoyi";
import api from "@/utils/request";
import { stopWebSocket } from "@/utils/helper";
const router = useRouter();
const deskNoticeSoundUrl= ref("");
const noticeList= ref([]);
const noticeValue = ref(0);
const unnoticeValue = ref(0);
const noticeFlag = ref(false);
const noticeDrawer = ref(false);
const restaurantName = ref(""); //餐厅名
const userName = ref(""); //用户名
const userNumber = ref(""); //登录账户
const password = ref(""); //登陆密码
const changeShow = ref(false);
const roleId = ref(""); //权限
const systemVersion = ref(""); //发布版本
const showList = ref(false);
const resList = ref([]); //餐厅列表
const menulist = ref([
  {
    name: "预订",
    img: require("@/assets/menu-img/yvding.png"),
    show: true,
    children: [
      {
        name: "今日订单",
        img: require("@/assets/menu-img/jinridingdan1.png"),
        img_select: require("@/assets/menu-img/jinridingdan2.png"),
        router: "/index",
        show: true,
      },
      {
        name: "今日预约",
        img: require("@/assets/menu-img/jinriyvyue1.png"),
        img_select: require("@/assets/menu-img/jinriyvyue2.png"),
        router: "/todayorder",
        show: true,
      },
      // {
      // 	name:'来电记录',
      // 	img:require('@/assets/menu-img/dianhua1.png'),
      // 	img_select:require('@/assets/menu-img/dianhua2.png'),
      // 	router:'/call'
      // },
      {
        name: "锁台/解锁",
        img: require("@/assets/menu-img/suotai1.png"),
        img_select: require("@/assets/menu-img/suotai2.png"),
        router: "/lockTable",
        show: true,
      },
      // {
      // 	name:'解锁',
      // 	img:require('@/assets/menu-img/jiesuo1.png'),
      // 	img_select:require('@/assets/menu-img/jiesuo2.png'),
      // 	router:'/unlockTable',
      // 	show: true
      // }
    ],
  },
  {
    name: "订单",
    img: require("@/assets/menu-img/dingdan.png"),
    show: true,
    children: [
      {
        name: "预订通知单",
        img: require("@/assets/menu-img/yvdingtongzhidan1.png"),
        img_select: require("@/assets/menu-img/yvdingtongzhidan2.png"),
        router: "/noticelist",
        show: true,
      },
      {
        name: "历史订单",
        img: require("@/assets/menu-img/lishidingdan1.png"),
        img_select: require("@/assets/menu-img/lishidingdan2.png"),
        router: "/historyNoticelist",
        show: true,
      },
      {
        name: "金额录入",
        img: require("@/assets/menu-img/jineluru1.png"),
        img_select: require("@/assets/menu-img/jineluru2.png"),
        router: "/entryOrder",
        show: true,
      },
      {
        name: "撤单统计",
        img: require("@/assets/menu-img/chedan1.png"),
        img_select: require("@/assets/menu-img/chedan2.png"),
        router: "/cancellationlist",
        show: true,
      },
    ],
  },
  {
    name: "客户",
    img: require("@/assets/menu-img/kehu.png"),
    show: true,
    children: [
      {
        name: "客户列表",
        img: require("@/assets/menu-img/customer2.png"),
        img_select: require("@/assets/menu-img/customer2.png"),
        router: "/customer/list",
        show: true,
      },
      {
        name: "会员分类",
        img: require("@/assets/menu-img/huiyuanjiazhifenlei1.png"),
        img_select: require("@/assets/menu-img/huiyuanjiazhifenlei2.png"),
        router: "/business/customers",
        show: true,
      },
      {
        name: "标签设置",
        img: require("@/assets/menu-img/biaoqianshezhi1.png"),
        img_select: require("@/assets/menu-img/biaoqianshezhi2.png"),
        router: "/business/label",
        show: true,
      },
    ],
  },
  {
    name: "财务",
    img: require("@/assets/menu-img/yeji.png"),
    show: true,
    children: [
      {
        name: "销售业绩",
        img: require("@/assets/menu-img/mesrecord1.png"),
        img_select: require("@/assets/menu-img/mesrecord2.png"),
        router: "/sales",
        show: true,
      },
      // 	{
      // 	name:'客户结构',
      // 	img:require('@/assets/menu-img/mesrecord1.png'),
      // 	img_select:require('@/assets/menu-img/mesrecord2.png'),
      // 	router:'/customer',
      // 	show: true
      // },
      {
        name: "客情分析",
        img: require("@/assets/menu-img/mesrecord1.png"),
        img_select: require("@/assets/menu-img/mesrecord2.png"),
        router: "/case",
        show: true,
      },
      {
        name: "客户排名",
        img: require("@/assets/menu-img/mesrecord1.png"),
        img_select: require("@/assets/menu-img/mesrecord2.png"),
        router: "/ranking",
        show: true,
      },
      {
        name: "订单明细",
        img: require("@/assets/menu-img/mesrecord1.png"),
        img_select: require("@/assets/menu-img/mesrecord2.png"),
        router: "/order",
        show: true,
      },
    ],
  },
  {
    name: "短信",
    img: require("@/assets/menu-img/shortmessage.png"),
    show: true,
    children: [
      {
        name: "短信发送记录",
        img: require("@/assets/menu-img/mesrecord1.png"),
        img_select: require("@/assets/menu-img/mesrecord2.png"),
        router: "/message/mesrecord",
        show: true,
      },
      {
        name: "短信模板设置",
        img: require("@/assets/menu-img/setmessage1.png"),
        img_select: require("@/assets/menu-img/setmessage2.png"),
        router: "/message/messagelist",
        show: true,
      },
      {
        name: "短信充值",
        img: require("@/assets/menu-img/mesrecharge1.png"),
        img_select: require("@/assets/menu-img/mesrecharge2.png"),
        router: "/message/mesrecharge",
        show: true,
      },
    ],
  },
  {
    name: "菜品",
    img: require("@/assets/menu-img/yingyeshezhi.png"),
    show: true,
    children: [
      {
        name: "菜品库",
        img: require("@/assets/menu-img/caipinku1.png"),
        img_select: require("@/assets/menu-img/caipinku2.png"),
        router: "/business/dishes",
        show: true,
      },
      {
        name: "套餐库",
        img: require("@/assets/menu-img/taocanku1.png"),
        img_select: require("@/assets/menu-img/taocanku2.png"),
        router: "/business/package",
        show: true,
      },
    ],
  },
  {
    name: "用户",
    img: require("@/assets/menu-img/xitongshezhi.png"),
    show: true,
    children: [
      {
        name: "用户管理",
        img: require("@/assets/menu-img/userManage1.png"),
        img_select: require("@/assets/menu-img/userManage2.png"),
        router: "/user",
        show: true,
      },
      {
        name: "部门管理",
        img: require("@/assets/menu-img/deptManage1.png"),
        img_select: require("@/assets/menu-img/deptManage2.png"),
        router: "/dept",
        show: true,
      },
      {
        name: "角色管理",
        img: require("@/assets/menu-img/roleManage1.png"),
        img_select: require("@/assets/menu-img/roleManage2.png"),
        router: "/role",
        show: true,
      },
      {
        name: "操作流程优化",
        img: require("@/assets/menu-img/deptManage1.png"),
        img_select: require("@/assets/menu-img/deptManage2.png"),
        router: "/optimize",
        show: true,
      },
    ],
  },
  {
    name: "设置",
    img: require("@/assets/menu-img/yvdingshezhi.png"),
    show: true,
    children: [
      {
        name: "餐厅信息",
        img: require("@/assets/menu-img/cantingxinxi1.png"),
        img_select: require("@/assets/menu-img/cantingxinxi2.png"),
        router: "/booking/restaurant",
        show: true,
      },
      {
        name: "餐位设置",
        img: require("@/assets/menu-img/canweishezhi1.png"),
        img_select: require("@/assets/menu-img/canweishezhi2.png"),
        router: "/setLocation",
        show: true,
      },
      {
        name: "餐段设置",
        img: require("@/assets/menu-img/canduanshezhi1.png"),
        img_select: require("@/assets/menu-img/canduanshezhi2.png"),
        router: "/setTimes",
        show: true,
      },
      {
        name: "用餐类型",
        img: require("@/assets/menu-img/candanleixing1.png"),
        img_select: require("@/assets/menu-img/candanleixing2.png"),
        router: "/setType",
        show: true,
      },
      {
        name: "好日子设置",
        img: require("@/assets/menu-img/haorizi1.png"),
        img_select: require("@/assets/menu-img/haorizi2.png"),
        router: "/goodDay",
        show: true,
      },
      {
        name: "财务结算科目",
        img: require("@/assets/menu-img/caiwujiesuankemu1.png"),
        img_select: require("@/assets/menu-img/caiwujiesuankemu2.png"),
        router: "/business/financial",
        show: true,
      },
    ],
  },
]);
const menuindex = ref(0);
const changeMenu = (index, menu) => {
  if (menu.name === "用户") {
    if (roleId.value > 1) {
      ElMessage({
        type: "error",
        message: "您暂时无权限修改用户角色",
      });
      return false;
    }
  }
  menuindex.value = index;
  if (menulist.value[index].children) {
    router.push(menulist.value[index].children[0].router);
  }
  if (menulist.value[index].router) {
    router.push(menulist.value[index].router);
  }
};
// 获取餐厅信息
const getLoginRestaurant = () =>
  getRestaurant().then((res) => {
    if (res.data.code === 200) {
      let data = res.data.data;
      restaurantName.value = data.restaurant.restaurantName;
      deskNoticeSoundUrl.value = data.restaurant.deskNoticeSoundUrl;
      localStorage.setItem("restaurantid", data.restaurant.restaurantId);
      localStorage.setItem("restaurant", JSON.stringify(data.restaurant));
    }
  });
// 获取当前用户餐厅
const getRestaurantList = () => {
  getRestaurantList_api().then((res) => {
    if (res.data.code === 200) {
      resList.value = [];
      for (let el of res.data.data) {
        resList.value.push({
          name: el.restaurantName,
          time: el.login_date,
          token: el.access_token,
        });
      }
    }
  });
};
// 切换餐厅
const changeRes = (r) => {
  localStorage.setItem("token", r.token);
  location.reload();
};
// 取消修改密码
const cancel = () => {
  changeShow.value = false;
  password.value = false;
};
// 修改密码
const change = () => {
  if (password.value) {
    changePassword({ password: password.value })
      .then((res) => {
        if (res.data.code === 200) {
          ElMessage({
            type: "success",
            message: "密码修改成功",
          });
          changeShow.value = false;
        } else {
          ElMessage({
            type: "error",
            message: res.data.msg ?? "密码修改失败",
          });
        }
      })
      .catch(() => {});
  } else {
    ElMessage({
      type: "error",
      message: "请输入新密码",
    });
  }
};
  const timeago=(dateTimeStamp)=>{  
	dateTimeStamp=new Date(dateTimeStamp);
        var minute = 1000 * 60;      //把分，时，天，周，半个月，一个月用毫秒表示
        var hour = minute * 60;
        var day = hour * 24;
		  var week = day * 7;
        var month = day * 30;
        var now = new Date().getTime();   //获取当前时间毫秒
        console.log(now)
        var diffValue = now - dateTimeStamp;//时间差
    let result ='';
        if(diffValue < 0){
            return;
        }
        var minC = diffValue/minute;  //计算时间差的分，时，天，周，月
        var hourC = diffValue/hour;
		  var dayC = diffValue/day;
        var weekC = diffValue/week;
        var monthC = diffValue/month;
        if(monthC >= 1 && monthC <= 3){
           result = new Date(dateTimeStamp).getMonth()+'-'+new Date(dateTimeStamp).getDate();
        }else if(weekC >= 1 && weekC <= 3){
           result = new Date(dateTimeStamp).getMonth()+'-'+new Date(dateTimeStamp).getDate();
        }else if(dayC >= 1 && dayC <= 6){
              result = new Date(dateTimeStamp).getMonth()+'-'+new Date(dateTimeStamp).getDate();
        }else if(hourC >= 1 && hourC <= 23){
            result = " " + parseInt(hourC) + "小时前"
        }else if(minC >= 1 && minC <= 59){
            result =" " + parseInt(minC) + "分钟前"
        }else if(diffValue >= 0 && diffValue <= minute){
            result = "刚刚"
        }else {
            var datetime = new Date();
            datetime.setTime(dateTimeStamp);
            var Nyear = datetime.getFullYear();
            var Nmonth = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
            var Ndate = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
            var Nhour = datetime.getHours() < 10 ? "0" + datetime.getHours() : datetime.getHours();
            var Nminute = datetime.getMinutes() < 10 ? "0" + datetime.getMinutes() : datetime.getMinutes();
            var Nsecond = datetime.getSeconds() < 10 ? "0" + datetime.getSeconds() : datetime.getSeconds();
            result = Nyear + "-" + Nmonth + "-" + Ndate
        }
        return result;
  }
const getNoticeListHandle=()=>{
getNoticeList().then(res=>{
noticeList.value=res.data.rows;
noticeValue.value=noticeList.value.filter(p=>p.status==='1').length;
unnoticeValue.value=noticeList.value.filter(p=>p.status==='0').length;
})
}
getNoticeListHandle();
const showNotice=()=>{
noticeDrawer.value=true;
getNoticeListHandle();
}

const noticeClick=(item)=>{
	if(item.status==='1'){
		return;
	}
	editNoticeStatus({id:item.id,status:'1'}).then(res=>{
			if(res.data.code===200){
getNoticeListHandle();
			}
})
}
const allRead=()=>{
toNoticeHas().then(res=>{
	if(res.data.code===200){
		noticeValue.value=res.data.data.read;
		unnoticeValue.value=res.data.data.unread;
	}
})
}
const noticeChange = () => {
  switchCloseNotice();
  // .then(res=>{
  //  	if(res.data.code===200){
  // 		noticeFlag.value=res.data.data
  // 	}else{
  // 		noticeFlag.value=!noticeFlag.value;
  // 	}
  // })
};

bus.on("listenWebSocket", (data) => {
  if (data.eventSource.toUpperCase() == "BACK_TO_DESKTOP") {
    console.log("BACK_TO_DESKTOP", data);
    unnoticeValue.value = data.count || 0;
    // 创建一个新的Audio对象
    var audio = new Audio(data.alert);
  
    // 播放音频
    audio.play();
  }
});
bus.on("refreshMenu", () => {
  // console.log("刷新新菜单");
  refreshSystemControl();
  // console.log("刷新新菜单成功");
});
// 获取用户信息
const getLoginUserInfo = () =>
  getUserinfo()
    .then((res) => {
      if (res.data.code === 200) {
        let user = res.data.user;
        userName.value = user.nickName;
        userNumber.value = user.userName;
        roleId.value = user.roleId;

        // 用户角色
        if (user.roleId > 1) {
          menulist.value[5].show = false;
        } else {
          menulist.value[5].show = true;
        }
        localStorage.setItem("user", JSON.stringify(res.data.user));
        localStorage.setItem("roles", JSON.stringify(res.data.roles));
        localStorage.setItem(
          "permissions",
          JSON.stringify(res.data.permissions)
        );
      } else if (res.data.code === 401) {
        router.push("/login");
      }
    })
    .catch(() => {});
const systemLogout = () => {
  stopWebSocket();
  api.logout();
  bus.emit("listenLogout");
  setTimeout(() => {
    router.push({ path: "/login" });
  }, 800);
};

/**
 * 设置系统控制开关
 */
const refreshSystemControl = () => {
  setSystemControlInfo()
    .then(() => {
      //设置今日预约
      menulist.value[0].children[1].show = localStorage.getItem("YuYue") == 1;
      //设置锁台/解锁
      menulist.value[0].children[2].show =
        localStorage.getItem("lockUnlock") == 1;
    })
    .catch(() => {});
};
getCloseNotice().then((res) => {
  noticeFlag.value = !res.data.data;
});
onMounted(() => {
  nextTick(() => {
    if (process.env.NODE_ENV === "production") {
      systemVersion.value = "";
    } else {
      systemVersion.value = process.env.VUE_APP_ENV;
    }
    refreshSystemControl();

    getLoginRestaurant();
    getLoginUserInfo();
    getRestaurantList();
  });

  let path = router.currentRoute.value.path;
  for (let i = 0; i < menulist.value.length; i++) {
    let back = false;
    let obj = menulist.value[i];
    if (obj.children) {
      for (let el of obj.children) {
        if (el.router === path) {
          menuindex.value = i;
          back = true;
          break;
        }
      }
      if (back) {
        break;
      }
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.flexbox {
  display: flex;
  align-items: center;
}
.reslistbox {
  .resbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    cursor: pointer;
    padding: 0 10px;
  }
  .resbox:hover {
    background-color: #f3f3f3;
  }
}
.el-main {
  --el-main-padding: 8px;
  display: block;
  flex: 1;
  flex-basis: auto;
  overflow: auto;
  box-sizing: border-box;
  padding: var(--el-main-padding);
}
.version {
  font-size: 24px;
  border: 5px solid #ffaa33;
}
.menu-box {
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  height: 50px;
}
.select {
  background: "#F3F3F3";
}
.menu-list .select {
  background: #f3f3f3;
}
.menu-box img {
  height: 20px;
  margin-right: 9px;
}
.facesbox {
  flex-direction: column;
  text-align: center;
  margin-right: 20px;
}
.menu-leftbox div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 12px;
}
.menu-leftbox img {
  width: 30px;
  height: 30px;
}
.add-friends {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add-friends img {
  width: 17px;
  height: 19px;
  margin-top: 28px;
  cursor: pointer;
}
:deep(.el-dialog__body) {
  padding: 0 20px 20px 20px;
}
.notice-drawer {
  margin-top: -20px;
  overflow: auto;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
    height: 50px;
}
.head-item{
	padding-bottom: 10px;
}
.notice-item{
	display:flex;
	  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
border-top: 1px solid #dddddd;
cursor: pointer;
&.gray{
		filter: opacity(0.5);
	}
}
.notice-name{
	font-weight: 600;
	&.red{
		color: red;
	}
	&.blue{
		color: blue;
	}	
}
:deep(.el-drawer__header){
	margin-bottom: 10px;
}

</style>

/*
 * @Author: yanjinzhong
 * @Date: 2022-02-15 11:17:10
 * @LastEditors: yanjinzhong
 * @LastEditTime: 2022-02-15 16:23:45
 */
import { createApp } from 'vue';
import store from './store';
import BaiduCalendar from "vue-baidu-calendar";
import VueAMap, {initAMapApiLoader} from '@vuemap/vue-amap';
import '@vuemap/vue-amap/dist/style.css';
initAMapApiLoader({
    key: 'bc05e3e6a0be27c0c9db62d307257376',
    securityJsCode: '7816a946f67e71a7f3ae9ce323b57e81',
})

import ElementPlus,{ElLoading} from 'element-plus';
import 'element-plus/dist/index.css';
import 'windi.css';
import './styles/element.css';
import './styles/common.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import 'xe-utils';
import VXETable from 'vxe-table';
import 'vxe-table/lib/style.css';
import App from './App.vue';
import router from './router/router';
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree } from "@/utils/ruoyi/ruoyi";
import { getConfigKey } from "@/api/system/config";
import vClickOutside from './directives/v-click-outside';
function useTable (app) {
app.use(VXETable)

// 给 vue 实例挂载内部对象，例如：
// app.config.globalProperties.$XModal = VXETable.modal
// app.config.globalProperties.$XPrint = VXETable.print
// app.config.globalProperties.$XSaveFile = VXETable.saveFile
// app.config.globalProperties.$XReadFile = VXETable.readFile
}

const app = createApp(App);
app.directive('click-outside', vClickOutside);
// 加载
let myLoadingObj = null;
const myLoadingFun = ()=>{
    myLoadingObj = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(255, 255, 255, 0.7)',
    });
}
const myCloseLoadingFun = ()=>{
    if(myLoadingObj){
        myLoadingObj.close();
    }
}
app.config.globalProperties.loading = myLoadingFun;
app.config.globalProperties.closeLoading = myCloseLoadingFun;
app.config.globalProperties.parseTime = parseTime;
app.config.globalProperties.resetForm = resetForm;
app.config.globalProperties.addDateRange = addDateRange;
app.config.globalProperties.selectDictLabel = selectDictLabel;
app.config.globalProperties.selectDictLabels = selectDictLabels;
app.config.globalProperties.handleTree = handleTree;
app.config.globalProperties.getConfigKey = getConfigKey;
app.use(ElementPlus,{locale: zhCn});
app.use(router);
app.use(store);
app.use(VueAMap);
app.use(BaiduCalendar);
app.use(useTable);
app.mount('#app');

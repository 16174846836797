// v-click-outside.js
export default {
    beforeMount(el, binding) {
      el.clickOutsideEvent = function(event) {
        // 检查点击是否在元素之外
        if (!(el == event.target || el.contains(event.target))) {
          // 如果点击在元素之外，则调用绑定的回调函数
          binding.value(event);
        }
      };
      // 添加全局点击事件监听器
      document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
      // 移除全局点击事件监听器
      document.body.removeEventListener('click', el.clickOutsideEvent);
    },
  };
  
<template>
    <div style="height: 100%;display: flex;flex-direction: column;">
        <el-row v-if="router.currentRoute.value.fullPath!=='/call'&&router.currentRoute.value.fullPath!=='/todayorder'"
                type="flex" style="margin-bottom: 11px;height: 58px;background: #FFFFFF;box-shadow: 0 1px 1px 1px #D6D5D5;align-items: center;border-radius: 6px;">
            <div class="meal-message flexbox" style="position:relative;">
                <div class="datebox" style="color: #333333;margin-right: 0;color: #FFFFFF;margin-left: 10px;">
                    <img style="width: 23px;height: 22px;" src="@/assets/banquet-img/rili2.png" alt="">日历
                </div>
                <div class="meal-date" style="margin: 0 15px;padding-right: 15px;border-right: 1px solid #FFFFFF;">
                    <div style="font-size: 16px;font-weight: bold;">
                        {{mealDate}}
                    </div>
                    <!-- <div>{{farmingDate}}</div> -->
                    <div>{{calendar.solar2lunar(mealDate.split('-')[0],mealDate.split('-')[1],mealDate.split('-')[2]).ncWeek+'&nbsp;&nbsp;'+calendar.solar2lunar(mealDate.split('-')[0],mealDate.split('-')[1],mealDate.split('-')[2]).IMonthCn+calendar.solar2lunar(mealDate.split('-')[0],mealDate.split('-')[1],mealDate.split('-')[2]).IDayCn}}</div>
                </div>
                <div style="text-align: center;">
                    <img src="@/assets/banquet-img/zaocan-baozi.png" style="width: 29px;height: 27px;display: block;" alt="">{{mealtype.segmentname}}
                </div>
                <div style="position:absolute;left: 0;top: 50px;background: #FFFFFF;z-index: 10;" v-show="calendarShow">
                    <banquet-calendar v-if="calShow" :selectDate="mealDate" @choiceDate="changeDate"></banquet-calendar>
                </div>
                <div @click="calendarShow=!calendarShow"  style="width:100%;height:100%;position: absolute;top:0;left:0;"></div>
            </div>
            <div class="header-databox flexbox" style="margin-left: 22px;border-right: 1px solid #E7E7E7;height: 100%;">
                <!-- <div class="datebox" @click="switchDay(1)" :class="choiceDay===1?'select':''">
                    <div class="iconimg">今</div>{{todayDate}}
                </div>
                <div class="datebox" @click="switchDay(2)" :class="choiceDay===2?'select':''">
                    <div class="iconimg">明</div>{{tomorrowDate}}
                </div>
                <div class="datebox" @click="switchDay(3)" :class="choiceDay===3?'select':''">
                    <div class="iconimg">后</div>{{afterTomorrow}}
                </div> -->
                <div class="datebox" @click="switchDay(i+1)" :class="choiceDay===i+1?'select':''" v-for="d,i in datelist" :key="i">
                    <div class="iconimg">{{ d.name }}</div>{{d.date}}
                </div>
            </div>
            <div class="mealslot-box flexbox" style="height: 100%;">
                <div :key="types.segmenttype" v-for="types in mealtypes" style="height:100%;">
                    <!-- <el-tooltip
                        v-if="(mealDate+' '+types.endtime)<nowTime"
                        effect="light"
                        content="餐段时间已过"
                        placement="bottom"
                    >
                        <div class="meal-slot disabletime">
                            <span class="border-color"><img :src="mealTypeImageList[types.segmenttype-1]" alt=""></span>{{types.segmentname}}
                        </div>
                    </el-tooltip>
                    <div v-else @click="choiceMealtype(types)" :class="mealtype.segmenttype===types.segmenttype?'select':''" class="meal-slot">
                        <span class="border-color"><img :src="mealTypeImageList[types.segmenttype-1]" alt=""></span>{{types.segmentname}}
                    </div> -->
                    <div @click="choiceMealtype(types)" :class="mealtype.segmenttype===types.segmenttype?'select':''" class="meal-slot">
                        <span class="border-color"><img :src="mealTypeImageList[types.segmenttype-1]" alt=""></span>{{types.segmentname}}
                    </div>
                </div>
            </div>
            <button @click="toCreate" class="createorder" type="button"><img src="@/assets/banquet-img/add.png" style="width: 16px;height: 16px;margin-right: 5px;vertical-align: middle;" alt="">创建订单</button>
            <div v-if="router.currentRoute.value.fullPath==='/index'" style="flex: 1;text-align: right;">
                <el-button class="el-print" type="danger" plain @click="printDialog">
                    <img style="height: 20px;margin-right: 10px;" src="@/assets/menu-img/print.png" alt="">
                    打印
                </el-button>
            </div>
        </el-row>
        <el-row :gutter="12" style="flex: 1;padding: 0px;overflow: hidden;">
            <el-col class="outbox" :span="callShow?9:14" style="height: 100%;overflow: auto;">
                <div style="padding: 0px 12px 8px 12px;background: #FFFFFF;box-sizing: border-box;min-height:100%;">
                    <div class="tablelist-box">
                        <div v-for="area in arealist" :key="area.areaid" class="hall-box">
                            <div class="hall-name">
                                {{area.areaname}}（{{area.desklist.length}}桌）
                            </div>
                            <div class="hall-list">
                                <template v-for="desk in area.desklist" :key="desk.desk.deskid">
                                    <div class="hall" :style="{
                                        '--sanke-back-color': colors.sankeBackColor,
                                        '--sanke-front-color': colors.sankeFrontColor,
                                        '--yvding-back-color': colors.yvdingBackColor,
                                        '--ding-back-fotter': colors.dingBackFotterColor,
                                        '--select-back-color': colors.selectBackColor,
                                    }">
                                        <div @click="selectTable(desk,desk.ordertype)" v-if="selectDeskId.indexOf(desk.desk.deskid)<0">
                                            <div v-if="desk.orderstatus===2" class="yvding">
                                                <div class="desk-name">{{desk.desk.deskname}}</div>
                                                <div class="empty-body">
                                                    {{desk.order[0].customer.customerInfo}}
                                                </div>
                                                <div class="desk-fotter" style="justify-content:center;background-color: #EDF4FF;">
                                                    <span class="fotter-name" style="color: #448AF8;">客到</span>
                                                </div>
                                            </div>
                                            <div v-else-if="desk.ordertype===5" class="yan">
                                                <div class="desk-name">{{desk.desk.deskname}}</div>
                                                <div class="empty-body" v-if="!showTableType">
                                                    {{desk.desk.peoplemin}}~{{desk.desk.peoplemax}}人
                                                </div>
                                                <div class="empty-body" v-if="showTableType">
                                                    {{desk.desk.tableType}}&nbsp;
                                                </div>
                                                <div class="yan-fotter desk-fotter">
                                                    <span class="fotter-name">{{desk.order[0].receiverordername}}</span>宴
                                                </div>
                                            </div>
                                            <div v-else-if="desk.ordertype===1" class="yvding">
                                                <div class="desk-name">{{desk.desk.deskname}}</div>
                                                <div class="empty-body-ding">
                                                    <!-- {{desk.desk.peoplemin}}~{{desk.desk.peoplemax}}人 -->
                                                    {{desk.order[0].customer.customerInfo}}

                                                </div>
                                                <div class="ding-fotter desk-fotter">
                                                    <span class="fotter-name-ding">{{desk.order[0].receiverordername}}</span>
                                                    <span class="fotter-name-ding">{{desk.order[0].intentPlanPeoples}}人</span>
                                                </div>
                                            </div>
                                            <div v-else-if="desk.ordertype===2" class="yvliu">
                                                <div class="desk-name">{{desk.desk.deskname}}</div>
                                                <img style="width: 28px;margin: 6px auto;" src="@/assets/banquet-img/yvliu-desk.png" alt="">
                                                <div class="liu-fotter desk-fotter">
                                                    <span class="fotter-name">{{desk.order[0].receiverordername}}</span>留
                                                </div>
                                            </div>
                                            <el-tooltip
                                                v-else-if="desk.ordertype===6"
                                                class="box-item"
                                                :content="'锁台原因：'+desk.lockReason"
                                                placement="top">
                                                <div  class="suotai">
                                                    <div class="desk-name">{{desk.desk.deskname}}</div>
                                                    <img style="width: 28px;margin: 6px auto;" src="@/assets/banquet-img/suotai-desk.png" alt="">
                                                    <div class="suotai-fotter">
                                                        锁台
                                                    </div>
                                                </div>
                                            </el-tooltip>
                                            <el-tooltip
                                                v-else-if="desk.ordertype===7"
                                                class="box-item"
                                                :content="'锁台原因：'+desk.lockReason"
                                                placement="top"
                                            >
                                                <div  class="suotai">
                                                    <div class="desk-name">{{desk.desk.deskname}}</div>
                                                    <img style="width: 28px;margin: 6px auto;" src="@/assets/banquet-img/suotai-desk.png" alt="">
                                                    <div class="suotai-fotter">
                                                        {{desk.remark}}
                                                    </div>
                                                </div>
                                            </el-tooltip>
                                            <div v-else-if="desk.orderLock===11">
                                                <div  class="suotai">
                                                    <div class="desk-name">{{desk.desk.deskname}}</div>
                                                    <img style="width: 28px;margin: 6px auto;" src="@/assets/banquet-img/yvding-lock.png" alt="">
                                                    <div class="suotai-fotter">
                                                        预订权限
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else-if="desk.ordertype===4" class="sanke">
                                                <div class="desk-name">{{desk.desk.deskname}}</div>
                                                <div class="empty-body fotter-name-san" v-if="!showTableType">
                                                    {{desk.desk.peoplemin}}~{{desk.desk.peoplemax}}人
                                                </div>
                                                <div class="empty-body" v-if="showTableType">
                                                    {{desk.desk.tableType}}&nbsp;
                                                </div>
                                                <div class="san-fotter desk-fotter">
                                                    <span class="fotter-name-san">{{desk.order[0].receiverordername}}</span>散
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div class="desk-name">{{desk.desk.deskname}}</div>
                                                <div class="empty-body" v-if="!showTableType">
                                                    {{desk.desk.peoplemin}}~{{desk.desk.peoplemax}}人
                                                </div>
                                                <div class="empty-body" v-if="showTableType">
                                                    {{desk.desk.tableType}}&nbsp;
                                                </div>
                                                <div class="empty-fotter">
    <!--                                                <span>空台</span>-->
                                                </div>
                                            </div>
                                        </div>
                                        <div @click="selectTable(desk)" v-else class="select-deskbox">
                                            <div class="desk-name">{{desk.desk.deskname}}</div>
                                            <div class="select-desk" v-if="!showTableType">
                                                {{desk.desk.peoplemin}}~{{desk.desk.peoplemax}}人
                                            </div>
                                            <div class="select-desk" v-if="showTableType">
                                                {{desk.desk.tableType}}&nbsp;
                                            </div>
                                            <div style="position: absolute;z-index: 10;bottom: 0;left: 0;width: 100%;">
                                                <div v-if="desk.ordertype===2" class="liu-fotter desk-fotter">
                                                    <span class="fotter-name">{{desk.order[0].receiverordername}}</span>留
                                                </div>
                                                <div v-if="desk.ordertype===4" class="san-fotter desk-fotter">
                                                    <span class="fotter-name-san">{{desk.order[0].receiverordername}}</span>散
                                                </div>
                                                <div v-if="desk.ordertype===5" class="yan-fotter desk-fotter">
                                                    <span class="fotter-name">{{desk.order[0].receiverordername}}</span>宴
                                                </div>
                                                <div v-if="desk.ordertype===1" class="ding-fotter desk-fotter">
                                                    <span class="fotter-name-ding">{{desk.order[0].receiverordername}}</span>
                                                    <span class="fotter-name-ding">{{desk.order[0].intentPlanPeoples}}人</span>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col v-if="callShow" :span="6" style="padding: 0 10px;background-color: #FFFFFF;" class="outbox">
                <div class="callbox">
                    <call-list/>
                </div>
            </el-col>
            <el-col :span="callShow?9:10" class="outbox">
                <router-view v-slot="{Component}">
                    <!-- <transition name="fade" mode="out-in"> -->
                        <component @getDeskList="getDeskList" :is="Component" />
                    <!-- </transition>   -->
                </router-view>
            </el-col>
        </el-row>
    </div>
</template>
<script setup>
import {onMounted,ref,onBeforeUnmount, nextTick,watch,computed} from 'vue';
import {getDeskOrder,findRestaurantMealTime,sendOccupyTable,clearALlSelectedTables } from '@/api/home/home'
import { formatDate } from '@/utils/formatdate'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from "vuex"
import callList from '@/views/order/callList.vue'
import calendar from '@/utils/js-calendar-converter';

import bus from '@/utils/bus';
// import BanquetCalendar from './order/BanquetCalendar.vue';
import BanquetCalendar from '@/components/common/calendar.vue'
import {startWebSocket,stopWebSocket} from '@/utils/helper'
import { ElMessage } from 'element-plus';

const router = useRouter();
const store = useStore();
const mealtype = ref(store.state.order.mealtype); //当前餐段
const mealtypes = ref([]); //餐段列表
const arealist = ref([]); //区域列表
const calendarShow = ref(false); //是否显示日历
const choiceDay = ref(1); //选中哪天
const selectDeskList = ref([]); //选中的桌位
const selectDeskId = ref([]); //选中的桌位id
const orderSelectId = ref([]);//当前订单选中的桌位id(防止在修改订单时取消桌位选择后桌位变为不可选)
const todayCalDate = ref(formatDate('MM月dd号',new Date()));
// const todayDate = ref(formatDate('M月d号',new Date()));
const nowTime = formatDate('yyyy-MM-dd hh:mm',new Date());
const date = new Date();
const mealTypeImageList = [require('@/assets/banquet-img/zaocan.png'),
                            require('@/assets/banquet-img/shangwucha.png'),
                            require('@/assets/banquet-img/wucan.png'),
                            require('@/assets/banquet-img/xiawucha.png'),
                            require('@/assets/banquet-img/wancan.png'),
                            require('@/assets/banquet-img/yexiao.png')];

const mealDate = ref(formatDate('yyyy-MM-dd',new Date()));
const colors = ref({
    sankeBackColor: '#EDF4FF',//最初样式
    //sankeBackColor: '#3E3CF4',//最新样式
    sankeFrontColor: '#448AF8',//最初样式
    //sankeFrontColor: '#F7F7F7',//最新样式
    yvdingBackColor: '#FFA7A7',//最初样式
    //yvdingBackColor: '#E32B3D',//最新样式
    dingBackFotterColor: '#FFA7A7', //最初样式
    //dingBackFotterColor: '#E32B3D', //最新样式
    selectBackColor: '#3F3CFF',//最初样式
    //selectBackColor: '#25B661',//最新样式
});
if(sessionStorage.getItem('mealdate')){
    if(sessionStorage.getItem('mealdate')<formatDate('yyyy-MM-dd',new Date())){
        sessionStorage.setItem('mealdate',formatDate('yyyy-MM-dd',new Date()))
    }
    // let datearr = formatDate('yyyy-MM-dd',new Date()).split("-");
    mealDate.value = sessionStorage.getItem('mealdate');
    choiceDay.value = ((new Date(mealDate.value))-(new Date(formatDate('yyyy-MM-dd',new Date()))))/1000/60/60/24+1;
    // if(mealDate.value === formatDate('yyyy-MM-dd',new Date())){
    //     choiceDay.value = 1
    // }else if (mealDate.value === formatDate('yyyy-MM-dd',new Date(datearr[0],datearr[1]-1,datearr[2]-0+1))){
    //     choiceDay.value = 2
    // }else if (mealDate.value === formatDate('yyyy-MM-dd',new Date(datearr[0],datearr[1]-1,datearr[2]-0+2))){
    //     choiceDay.value = 3
    // }else{
    //     choiceDay.value = 0
    // }
}
const callShow = ref(false)

// const farmingDate = ref();
const datearr = formatDate('yyyy-MM-dd',new Date()).split('-');
// const tomorrowDate = ref(formatDate('M月d号',new Date(datearr[0],datearr[1]-1,datearr[2]-0+1))); //明天
// const afterTomorrow = ref(formatDate('M月d号',new Date(datearr[0],datearr[1]-1,datearr[2]-0+2))); //后天
const calShow = ref(true);
const showTableType = ref(false);//是否显示台型
const selectedLockDesks = ref([]);
const user = ref(null);
const restaurant = ref(null);
const canselectdesk = computed(()=>store.state.order.canselectdesk)
const watchDate = computed(()=>store.state.order.date); //监听日期
const watchMealType = computed(()=>store.state.order.mealtype);
const datelist = ref([
    {name:'今',date:ref(formatDate('M月d号',new Date()))},
    {name:'明',date:ref(formatDate('M月d号',new Date(datearr[0],datearr[1]-1,datearr[2]-0+1)))},
    {name:'后',date:ref(formatDate('M月d号',new Date(datearr[0],datearr[1]-1,datearr[2]-0+2)))}]);
const createDateList = ()=>{
    const date = new Date();
    const date_year = date.getFullYear();
    const date_month = date.getMonth();
    const date_day = date.getDate();
    for(let i = 0;i < 4;i ++){
        let date_str = new Date(date_year,date_month,date_day+3+i).getDate()+'日';
        datelist.value.push({
            name:date_str,
            date:(formatDate('M月d号',new Date(date_year,date_month,date_day+3+i)))
        })
    }
}
watch(watchDate,(newdata)=>{
    if(newdata!==formatDate('yyyy-MM-dd',mealDate.value)){
        mealDate.value = newdata;
        if(mealDate.value === formatDate('yyyy-MM-dd',new Date())){
            choiceDay.value = 1
        }else if (mealDate.value === formatDate('yyyy-MM-dd',new Date(datearr[0],datearr[1]-1,datearr[2]-0+1))){
            choiceDay.value = 2
        }else if (mealDate.value === formatDate('yyyy-MM-dd',new Date(datearr[0],datearr[1]-1,datearr[2]-0+2))){
            choiceDay.value = 3
        }else{
            choiceDay.value = 0
        }
        if(mealtype.value.segmenttype){
            getDeskList();
        }
    }
})
watch(watchMealType,(newdata)=>{
    if(JSON.stringify(newdata)!==JSON.stringify(mealtype.value)){
        mealtype.value = newdata;
        if(mealDate.value){
            getDeskList();
        }
    }
})
store.dispatch('changeDate',mealDate.value);
// 跳转创建订单
const toCreate = () => {
    // console.log(mealDate.value+' '+mealtype.value.endtime,nowTime)
    if((mealDate.value+' '+mealtype.value.endtime)>nowTime){
        router.push({path:'/createorder/2'})
    }else{
        ElMessage({
            message:'餐段时间已过，不允许创建订单',
            type:'error'
        })
    }
    
}
// 获取区域列表和桌位列表
const getDeskList = () => getDeskOrder({mealDate:mealDate.value,segmentType:mealtype.value.segmenttype}).then(res => {
    if(route.name!=='createorder'){
        selectDeskId.value = [];
        selectDeskList.value = [];
    }
    let db_data = res.data.data;
    arealist.value = [];
    if(db_data){
        db_data.forEach(area=>{
            let objDeskOrders= JSON.parse(JSON.stringify(area.deskOrders));
            setSelectedInfoByFromDb(objDeskOrders);
            arealist.value.push({areaid:area.areaid,areaname:area.areaname,desklist:objDeskOrders});
        });
    }

}).then(()=>{
    for(let area of arealist.value){
        if(route.name==='createorder'){
            area.desklist.forEach(el=>{
                if(el.order){
                    if(el.order[0].orderid!==route.params.id){
                        let index = selectDeskId.value.indexOf(el.desk.deskid);
                        if(index>-1){
                            selectDeskId.value.splice(index,1);
                            selectDeskList.value.splice(index,1);
                            bus.emit('canceldesk',el)
                        }
                        store.dispatch('changeDesklist',selectDeskList)
                    }
                }
            })
        }
    }
}).catch(()=>{});
// /**
//  * Websocket用于刷新订单数据
//  */
// const getDeskListForSocket = () => getDeskOrder({mealDate:mealDate.value,segmentType:mealtype.value.segmenttype}).then(res => {
//     let db_data = res.data.data;
//     arealist.value = [];
//     if(db_data){
//         db_data.forEach(area=>{
//             setSelectedInfoByFromDb(area.deskOrders);
//             arealist.value.push({areaid:area.areaid,areaname:area.areaname,desklist:area.deskOrders});
//         });
//     }
// })
bus.on('getTable',()=>{
    getDeskList();
})
bus.on('listenLogout',()=>{
    // console.log("监听到系统已退出");

    unloadEvent();
})
bus.on('listenWebSocket',data=>{
    if(data.eventSource.toUpperCase() == 'BACK_TO_FRONT'){
        // console.log("新订单已成，资源释放通知："+JSON.stringify(data));

        resetDeskSelectedToBlank(data);
        getDeskList();
    }
    //定时解锁
    if(data.eventSource.toUpperCase() == 'REDIS_TO_FRONT'){
        // console.log("3分钟已达释放通知："+JSON.stringify(data));

        setDeskLockToStatus(data);
        resetDeskSelectedToBlank(data);
    }
    //锁桌子
    if(data.eventSource.toUpperCase() == 'FRONT_TO_BACK'){

        // if((data.eatDate+data.segmentType)!==(mealDate.value+mealtype.value.segmenttype)){
        //     return;
        // }
        if(user.value && user.value.userId == data.occupierId){
            data.lockedByMe = 1;
            if(selectDeskId.value.indexOf(data.tableId)<0){

                let selectedTable = getDeskInfoByDeskId(data.eatDate,data.segmentType,data.tableId);
                if(selectedTable){
                    setSelectBlueStatus(JSON.parse(selectedTable));
                }
            }
            
        }else{
            data.lockedByMe = 0;
        }

        // console.log("锁桌通知："+JSON.stringify(data));
        setDeskLockToStatus(data);

        resetDeskSelectedToBlank(data);
    }
    //来电--自动跳转暂时不处理
    if(data.eventSource.toUpperCase() == 'TEL_TO_FRONT'){
        // console.log("来电信息："+JSON.stringify(data));
        bus.emit('refreshCallList');
    }

})
/**
 * 修改桌位状态到指定样子
 * @param {} data 
 */
const setDeskLockToStatus=(data)=>{
    arealist.value.forEach(area=>{
        area.desklist.forEach(desk => {

            if(desk.desk.deskid==data.tableId && mealDate.value == data.eatDate && mealtype.value.segmenttype == data.segmentType){
                // console.log(JSON.stringify(data));
                // console.log(JSON.stringify(desk));
                if(desk.ordertype == null && data.isSelected){
                    desk.ordertype = 7;
                    desk.lockReason = data.occupierName + "正在使用该餐位";
                    desk.remark = data.occupierName + "占桌";
                }else if(desk.ordertype == 7 && !data.isSelected){
                    desk.ordertype = null;
                    desk.lockReason = null;
                    desk.remark = null;
                }
                desk.lockedByMe = data.lockedByMe;
            }
        })
    });
}
/**
 * 反显时，取消之前的占桌状态
 * @param {} data 
 */
 const releaseDeskLock=()=>{

    arealist.value.forEach(area=>{
        area.desklist.forEach(desk => {
            if(desk.lockedByMe == 1){
                desk.ordertype = null;
            }
        })
    });
}
const printDialog = ()=>{
    bus.emit('print');
}
const resetDeskSelectedToBlank=(data)=>{
    // 如果选桌状态已清空，则把蓝底状态也恢复
    if(!data.isSelected){

        let index = selectDeskId.value.indexOf(data.tableId);
        if(index > -1){
            selectDeskList.value.splice(index,1);
            selectDeskId.value.splice(index,1);
        }
        setDeskSelectStatusTo(data,false);
    }
}
const setDeskSelectStatusTo=(data,isSelected)=>{
    arealist.value.forEach(area=>{
        area.desklist.forEach(desk => {

            if(desk.desk.deskid==data.tableId && mealDate.value == data.eatDate && mealtype.value.segmenttype == data.segmentType){
                desk.select = isSelected;
            }
        })
    });
}
/**
 * 根据桌位ID获取桌位信息
 * @param {*} deskId 
 */
const getDeskInfoByDeskId=(eatDate,segmentType,deskId)=>{

    let selected = "";
    arealist.value.forEach(area=>{
        area.desklist.forEach(desk => {
            if(desk.desk.deskid==deskId){
                selected =  JSON.stringify(desk);
            }
        })
    });
    return selected;
}
/**
 * 从DB获取的信息选择桌位
 * @param {*} deskId 
 */
 const setSelectedInfoByFromDb=(deskOrders)=>{
    deskOrders.forEach(desk => {
        let strDesk = JSON.stringify(desk);
        //预订锁台
        desk.orderLock = 0;
        if(user.value !==null && desk.permissionUserIDList !== null){
            let isInclude = desk.permissionUserIDList.includes(user.value.userId);

            if(isInclude !== true){
                desk.orderLock = 11;
            }
        }

        if(desk.lockedByMe == 1){
            if(selectDeskId.value.indexOf(desk.desk.deskid)<0){
                setSelectBlueStatus(JSON.parse(strDesk));
            }
        }
    })
}
 // 选中桌位回显
bus.on('deskshow',(desklist)=>{
    // let desklist = store.state.order.desklist;//选中的桌位信息
    
    selectDeskList.value = [];
    selectDeskId.value = [];
    orderSelectId.value = [];
    selectedLockDesks.value =[];
    if(desklist.length > 0){
        for(let el of desklist){
            selectDeskList.value.push(el);
            selectDeskId.value.push(el.desk.deskid);
            orderSelectId.value.push(el.desk.deskid);
        }
    }else{
        unlockDesks();
    }
    releaseDeskLock();
})
// 获取餐段信息
const getTimeTypes = () => findRestaurantMealTime().then(res=>{
    
    if(res.data.code===401){
        router.push({path:'/login'})
    }else if(res.data.code===200){
        let time = (date.getHours()<10?'0'+date.getHours():date.getHours())+':'+(date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes());
        let week = date.getDay();
        let timearr = [];
        let data = res.data.data;
        if(week === 0){
            week = 7;
        }
        for(let i = 0,len = data.length;i < len;i ++){
            let times = data[i]
            if(times.weekday===week){
                timearr.push(times);
                mealtypes.value.push({segmenttype:times.segmenttype,segmentname:times.segmentname,endtime:times.endtime,starttime:times.starttime,defaulttime:times.defaulttime});
                if(time>=times.starttime&&time<=times.endtime){
                    if(sessionStorage.getItem('mealtype')){
                        mealtype.value = JSON.parse(sessionStorage.getItem('mealtype'))
                    }else{
                        mealtype.value={segmenttype:times.segmenttype,segmentname:times.segmentname};
                    }
                    store.dispatch('changeMealtype',mealtype.value);
                    bus.emit('getMealType',mealtype.value);
                }
            }
                
        }
        bus.emit('currentMeatypes',mealtypes.value);
        if(!mealtype.value){
            for(let t = 0,tlen = timearr.length;t<tlen;t ++){
                let t_time = timearr[t];
                if(t<tlen-1){
                    if(time>t_time.endtime){
                        if(sessionStorage.getItem('mealtype')){
                            mealtype.value = JSON.parse(sessionStorage.getItem('mealtype'))
                        }else{
                            mealtype.value={segmenttype:timearr[t+1].segmenttype,segmentname:timearr[t+1].segmentname};
                        }
                    }
                }
            }
            if(!mealtype.value){
                if(sessionStorage.getItem('mealtype')){
                    mealtype.value = JSON.parse(sessionStorage.getItem('mealtype'));
                }else{
                    mealtype.value={segmenttype:timearr[0].segmenttype,segmentname:timearr[0].segmentname};
                }
                store.dispatch('changeMealtype',mealtype.value);
                bus.emit('getMealType',mealtype.value);
            }else{
                store.dispatch('changeMealtype',mealtype.value);
                bus.emit('getMealType',mealtype.value);
            }
        }
    }
    
    
}).finally(()=>{
    getDeskList();
    // bus.emit('getMealType',mealtype.value)
})
// 切换今、明、后
const switchDay = (d)=>{
    calShow.value = false;
    choiceDay.value = d;
    let datestr = '';
    // if(d===1){
    //     datestr = formatDate('yyyy-MM-dd',new Date());
    // }else if(d===2){
    //     datestr = formatDate('yyyy-MM-dd',new Date(datearr[0],datearr[1]-1,datearr[2]-0+1));
    // }else if(d===3){
    //    datestr = formatDate('yyyy-MM-dd',new Date(datearr[0],datearr[1]-1,datearr[2]-0+2));
    // }
    datestr = formatDate('yyyy-MM-dd',new Date(datearr[0],datearr[1]-1,datearr[2]-0+d-1));
    mealDate.value = datestr
    sessionStorage.setItem('mealdate',mealDate.value);
    store.dispatch('changeDate',datestr);
    bus.emit('getMealType',mealtype.value);
    if(mealtype.value.segmenttype){
        getDeskList();
    }
    setTimeout(()=>{
        calShow.value = true;
    },10)
}
// 选择餐段信息
const choiceMealtype = (type)=>{
    mealtype.value = type;
    sessionStorage.setItem('mealtype',JSON.stringify(type));
    getDeskList();
    store.dispatch('changeMealtype',type);
    bus.emit('getMealType',mealtype.value);
}
// 选择日期
const changeDate = (obj)=>{
    mealDate.value = formatDate('yyyy-MM-dd',new Date(obj.date));
    sessionStorage.setItem('mealdate',mealDate.value);
    if(mealDate.value === formatDate('yyyy-MM-dd',new Date())){
        choiceDay.value = 1
    }else if (mealDate.value === formatDate('yyyy-MM-dd',new Date(datearr[0],datearr[1]-1,datearr[2]-0+1))){
        choiceDay.value = 2
    }else if (mealDate.value === formatDate('yyyy-MM-dd',new Date(datearr[0],datearr[1]-1,datearr[2]-0+2))){
        choiceDay.value = 3
    }else{
        choiceDay.value = 0
    }
    if(mealtype.value.segmenttype){
        getDeskList();
    }
    store.dispatch('changeDate',formatDate('yyyy-MM-dd',new Date(obj.date)));
    // farmingDate.value = obj.ncWeek.replace('星期','周')+ " " +obj.monthCn + obj.dayCn;
    // store.dispatch('changeLunar',obj.monthCn + obj.dayCn+" "+obj.ncWeek.replace('星期','周'));
    bus.emit('getMealType',mealtype.value);
    todayCalDate.value = formatDate('yyyy-MM-dd',new Date(obj.date));
    calendarShow.value = false;
}
//发送锁桌消息
const occupyTable = (json) =>{
    // console.log("发送数据:"+JSON.stringify(json));
    sendOccupyTable(json).then(res=>{
        if(res.data.code == 200){
            // console.log('已通知其他终端');
        }else{
            ElMessage({
                type:'error',
                message:res.data.msg
            });
        }
    }).catch(()=>{});
}
const route = useRoute();
// 选择桌位
const selectTable = (desk,deskltype)=>{
    if(desk.orderLock == 11){
        ElMessage({
            type:'error',
            message:"您无该餐位预订权限，请联系预订台！"
        });
        return;
    }
    if(location.href.includes('changetable')){
        if(deskltype===6||(deskltype===7 && desk.lockedByMe !== 1)){
            return;
        }
        if(desk.order&&desk.order[0]&&desk.order[0].orderid){
            if(desk.order[0].orderid===route.params.id){
                return;
            }
        }
        // for(let area of arealist.value){
        //     for(let d of area.desklist){
        //         if(selectDeskId.value.includes(d.desk.deskid)&&d.desk.deskid!==desk.desk.deskid){
        //             d.select = false;
        //             controlDeskStatus(d,false,false);
        //         }
        //     }
        // }
        let desklist = JSON.parse(JSON.stringify(selectDeskList.value))
        if(!selectDeskId.value.includes(desk.desk.deskid)){
            for(let d of desklist){
                d.select = false;
                controlDeskStatus(d,false,false);
            }
            selectDeskList.value=[];
            selectDeskId.value=[];
            orderSelectId.value=[];
            desk.select = true;
            controlDeskStatus(desk,true,true);
            bus.emit('selectDesk',desk); //添加到订单
        }else{
            selectDeskList.value=[];
            selectDeskId.value=[];
            orderSelectId.value=[];
            desk.select = false;
            controlDeskStatus(desk,false,false);
            bus.emit('selectDesk',[]); //添加到订单
        }
        // selectDeskList.value=[];
        // selectDeskId.value=[];
        // orderSelectId.value=[];
        // if(selectDeskId.value.includes(desk.desk.deskid)){
        //     desk.select = false;
        //     controlDeskStatus(desk,false,false);
        // }else{
        //     desk.select = true;
        //     selectDeskList.value.push(desk);
        //     selectDeskId.value.push(desk.desk.deskid);
        //     orderSelectId.value.push(desk.desk.deskid);
        //     controlDeskStatus(desk,true,true);
        // }
    }else{
        if(!canselectdesk.value){
            return;
        }
        if(route.name==='index'){
            orderSelectId.value=[]
        }
        // 在经理选择画面中，其他画面产生订单后；刷新其他桌位状态而不影响选择桌位。
        if(selectDeskId.value.indexOf(desk.desk.deskid)>-1){
            desk.select = true;
            desk.ordertype = null;
            desk.lockReason = null;
            desk.remark = null;
        }
        if((deskltype===5||deskltype===1||deskltype===2||deskltype===6||(deskltype===7 && desk.lockedByMe !== 1)||deskltype===4)&&orderSelectId.value.indexOf(desk.desk.deskid)<0){
            // 根据桌位id对列表进行排序
            bus.emit('desksort',desk.desk.deskname);
            return false
        }   
        let selectStatus = desk.select == undefined || desk.select ==false;
        if(deskltype===7 && desk.lockedByMe == 1){
            desk.select = false;
            desk.ordertype = null;
            desk.lockReason = null;
            desk.remark = null;

            selectStatus = false;
            
            bus.emit('selectDesk',desk); //添加到订单
            controlDeskStatus(desk,selectStatus,false);
        }else{
            controlDeskStatus(desk,selectStatus,true);
            bus.emit('selectDesk',desk); //添加到订单
        }
    }
}
/**
 * 选择桌位处理
 * @param {*} desk 桌位信息
 * @param {*} selectStatus 选择桌位切换
 * @param {*} isSetBlue 是否做选择处理
 */
const controlDeskStatus=(desk,selectStatus,isSetBlue)=>{

    if(!user.value){
        user.value = JSON.parse(localStorage.getItem('user'));
    }
    const websocketJson = {
            tableId: desk.desk.deskid,
            eatDate: mealDate.value,
            segmentType: mealtype.value.segmenttype,
            isSelected: selectStatus
        }
        
    if(user.value){
        websocketJson.occupierId = user.value.userId;
        websocketJson.occupierName = user.value.nickName;
    }
    occupyTable(websocketJson);

    //预订画面锁桌后，离开后清桌
    let jsonString = JSON.stringify(websocketJson);
    if(!selectedLockDesks.value.includes(jsonString) && websocketJson.isSelected){
        selectedLockDesks.value.push(jsonString);
    }
    if(isSetBlue){
        setSelectBlueStatus(desk);
    }
}
/**
 * 设置桌位选择
 * @param {*} desk 
 */
const setSelectBlueStatus = (desk)=>{

    if(selectDeskId.value.indexOf(desk.desk.deskid)<0){
        selectDeskList.value.push(desk);
        selectDeskId.value.push(desk.desk.deskid);
        desk.select = true; //调桌加桌判断条件
    }else{
        let index = selectDeskId.value.indexOf(desk.desk.deskid);
        selectDeskList.value.splice(index,1);
        selectDeskId.value.splice(index,1);
        desk.select = false; //调桌加桌判断条件
    }
    // if(router.currentRoute.value.name==='index'&&selectDeskList.value.length){
    //     toCreate()
    // }
    // bus.emit('viewCreate',selectDeskList.value.length > 0);
    store.dispatch('changeDesklist',selectDeskList)
}

const unlockDesks = ()=>{
    selectedLockDesks.value.forEach(item=>{
        const lock = JSON.parse(item);

        lock.isSelected = false;
        occupyTable(lock);
    });
}
/**
 * 关闭浏览器时的后处理
 */
const unloadEvent = ()=>{
    const websocketJson = {
            eatDate: "*",
            segmentType: "*",
        }
    clearALlSelectedTables(websocketJson).then(()=>{
        stopWebSocket();
        bus.off('listenWebSocket');
    }).catch(()=>{});

}
onBeforeUnmount(()=>{
    unloadEvent();
    // console.log("已经退出预订菜单");
})
let _beforeUnload_time = 0;
let _gap_time = 0;

onMounted(()=>{
    window.onunload = async ()=>{
        _gap_time = new Date().getTime()-_beforeUnload_time;
        if(_gap_time<=5){
            await unloadEvent();
            // console.log("清空占桌已完成");
            return "";
        }
    }
    window.onbeforeunload = ()=>{
        _beforeUnload_time = new Date().getTime();
    }
    nextTick(()=>{
        createDateList();
        if(localStorage.getItem('view_homeLayout')==='0'){
            callShow.value = true;
        }
        getTimeTypes();
        user.value = JSON.parse(localStorage.getItem('user'));
        restaurant.value = JSON.parse(localStorage.getItem('restaurant'));
        
        if(restaurant.value){
            if(restaurant.value.sanBackColor){
                colors.value.sankeBackColor = restaurant.value.sanBackColor;
            }
            if(restaurant.value.sanFrontColor){
                colors.value.sankeFrontColor = restaurant.value.sanFrontColor;
            }
            if(restaurant.value.dingBackColor){
                colors.value.yvdingBackColor = restaurant.value.dingBackColor;
                colors.value.dingBackFotterColor = restaurant.value.dingBackColor;
            }
            if(restaurant.value.selectBackColor){
                colors.value.selectBackColor = restaurant.value.selectBackColor;
            }
        }
        //台型、人数展示方式
		showTableType.value = localStorage.getItem("view_tableType")==1;
        setTimeout(()=>{
            startWebSocket();
        },3000);
    })
})
</script>
<style scoped>

.flexbox{
    display: flex;
    align-items: center;
}

.outbox{
    height: 100%;
    overflow: auto;
}
/* 滚动条整体样式 */
.outbox::-webkit-scrollbar {
    display: none;
}

/*定义滚动条的轨道颜色、内阴影及圆角*/
.outbox::-webkit-scrollbar-track{
    display: none;
}


/*定义滑块颜色、内阴影及圆角*/
.outbox::-webkit-scrollbar-thumb{
    display: none;
}

.meal-message{
    background: url('../assets/banquet-img/background.png') no-repeat;
    height: 58px;
    width: 230px;
    background-size: 100% 100%;
    color: #FFFFFF;
    cursor:pointer;
}
.op-calendar-pc-backtoday{
    display: none !important;
}
.op-calendar-pc-select-box .op-calendar-pc-holiday-box:nth-last-child(2){
    display: none !important;
}
.datebox{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-right: 31px;
    color: #DFC1C1;
}
.header-databox .select{
    color: #333333;
}
.meal-slot{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 86px;
    border-right: 1px solid #E7E7E7;
    height: 100%;
    justify-content: center;
    cursor: pointer;
}
.meal-slot img{
    width: 28px;
    height: 25px;
    box-sizing: content-box;
}
.mealslot-box .select{
    background: #FE5353;
    color: #FFFFFF;
}
.mealslot-box .select img{
    border-radius: 25px;
    border: 2px solid #FFFFFF;
}
.disabletime{
    cursor: not-allowed;
    opacity: 0.5;
}
.createorder{
    height: 40px;
    border: none;
    background: #FF5353;
    color: #FFFFFF;
    border-radius: 40px;
    width: 120px;
    cursor: pointer;
    margin-left: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.table-statistics{
    width: 84px;
    height: 40px;
    border-radius: 6px;
    text-align: center;
}
.table-statistics span{
    font-size: 24px;
    font-weight: bold;
    line-height: 40px;
}
.hall-list{
    display: grid;
    grid-template-columns: repeat(auto-fill, 100px);
    justify-content: space-between;
    grid-row-gap: 20px;
}
.hall-name{
    font-size: 16px;
    font-weight: bold;
    padding: 18px 0;
}
.hall{
    border: 1px solid #E5E5E5;
    border-radius: 9px 9px 9px 9px;
    overflow: hidden;
    cursor: pointer;
}
.hall{
    text-align: center;
}
.hall-body{
    margin: 15px 10px 5px;
    display: flex;
    justify-content: space-between;
}
.empty-body{
    margin: 15px 10px 5px;
    color: #999999;
}
.empty-body-ding{
  margin: 15px 10px 5px;
  font-size: 13px;
  color: #FFFFFF;
}
.hall-footer{
    height: 30px;
    display: flex;
    padding: 0 10px;
    justify-content: space-between;
    align-items: center;
}
.hall-footer span{
    padding-right: 10px;
    border-right: 1px solid #EEE5E5;
}
.empty-fotter{
    height: 30px;
    background-color: #F7F7F7;
}
.empty-fotter span{
    display: inline-block;
    padding: 0 10px;
    color: #FFFFFF;
    height: 24px;
    line-height: 24px;
    margin-top: 3px;
    border-radius: 15px;
    line-height: 30px;
    background-color: #FE5353;
}
.fotter-name{
    font-size: 12px;
    color: #999999;
}
.fotter-name-ding{
  font-size: 12px;
  color: #FFFFFF;
}
.fotter-name-san{
  font-size: 12px;
  color: var(--sanke-front-color);
}
.desk-fotter{
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    align-items: center;
    height: 30px;
}
.yan-fotter{
    background-color: #FFEBEB;
    color: #EC0B0B;
}
.ding-fotter{
    background-color: var(--ding-back-fotter);
    color: #FF4500;
}
.liu-fotter{
    background-color: #F0FAF2;
    color: #339947;
}
.suotai-fotter{
    background-color: #FAEDFF;
    color: #9E3AC4;
    height: 30px;
    line-height: 30px;
}
.desk-name{
    padding-top: 10px;
}
.select-deskbox{
    color: #FFFFFF;
    background: var(--select-back-color);
    position: relative;
}
.select-desk{
    padding: 15px 10px 35px;
}
.yan{
    background-color: #FFEBEB;
    color: #EC0B0B;
}
.yvding{
    background-color: var(--yvding-back-color);
    color: #FFFFFF;
}
.yvliu{
    background-color: #F0FAF2;
    color: #339947;
}
.sanke{
    background: var(--sanke-back-color);
    color: var(--sanke-front-color);
}
.kedao{
    background: #FFEBEB;
}
.suotai{
    background-color: #FAEDFF;
    color: #9E3AC4;
}
.kongtai{
    background: #F7F7F7;
}
.el-print{
    height: 24px;
  line-height: 24px;
  padding: 15px;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  margin-right: 12px;
}
/* .fade-enter-active,.fade-leave-active{
    transition: opacity 0.3s ease;
}
.fade-enter-from,.fade-leave-to{
    opacity: 0;
} */
.callbox{
    background-color: #FFFFFF;
    height: 100%;
    overflow: auto;
}

</style>
<style>

.datebox .iconimg{
    width: 23px;
    height: 22px;
    line-height: 22px;
    color: #FFFFFF;
    background: #DFC1C1;
    font-size: 12px;
    text-align: center;
    border-radius: 4px;
}
.header-databox .select .iconimg{
    background: #FF5353;
}
.el-print{
    background: none;
}


</style>